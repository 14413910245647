import Dexie from 'dexie'

const dexieDb = new Dexie('hozSsvDb')

dexieDb.version(2).stores({
  auth: 'tokenKey',
  validationsQueue: '++id,recordedAt',
})

export async function db() {
  if (!dexieDb.isOpen()) {
    await dexieDb.open()
  }
  return dexieDb
}
