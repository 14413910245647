import { useConnectionInfoStore } from '@/monitoring'
import { useApi } from '@/api'
import { validationsQueue } from './store'
import { useIntervalFn } from '@vueuse/core'

const unsentWorkerIntervalSecs = 5

export const useValidationSubmission = () => {
  const connectionInfo = useConnectionInfoStore()
  const { exec, isLoading } = useApi({ url: '/ssv' })

  async function submitValidation({ vrm, recordedAt }) {
    if (connectionInfo.connectionState === 'UP') {
      await exec({
        method: 'POST',
        data: { vrm, recorded_at: recordedAt.toISO() },
      })
    } else {
      await validationsQueue.enqueue({ vrm, recordedAt })
    }
  }

  return {
    submitValidation,
    isLoading,
  }
}

export const useUnsentValidationWorker = () => {
  const connectionInfo = useConnectionInfoStore()
  const { exec } = useApi({ url: '/ssv' })
  useIntervalFn(async () => {
    if (connectionInfo.connectionState === 'UP') {
      const nextValidation = await validationsQueue.borrowNext()
      if (nextValidation !== undefined) {
        const { id, vrm, recordedAt } = nextValidation
        try {
          await exec({
            method: 'POST',
            data: {
              vrm,
              recorded_at: recordedAt.toISOString(),
            },
          })
          await validationsQueue.remove(id)
        } catch (e) {
          console.warn(e)
          await validationsQueue.release(id)
        }
      }
    }
  }, unsentWorkerIntervalSecs * 1000)
}
