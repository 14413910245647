import axios from 'axios'
import { ref } from 'vue'

import { appConfig } from '@/config'
import { useAuth } from '@/auth'

const requestTimeoutSecs = 5

const axiosInstance = axios.create({
  baseURL: appConfig.apiBaseUrl,
  timeout: requestTimeoutSecs * 1000,
})

export const useApi = ({ url }) => {
  const isLoading = ref(false)
  const response = ref(null)
  const { getToken } = useAuth()

  const exec = async ({ method = 'GET', data }) => {
    isLoading.value = true
    const token = await getToken()
    try {
      response.value = await axiosInstance({
        method,
        data,
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } finally {
      isLoading.value = false
    }
  }

  return {
    exec,
    isLoading,
    response,
  }
}
