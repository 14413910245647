import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import { authGuard } from '@/auth'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/vrm',
      name: 'vrm-enter',
      component: () => import('../views/VrmEntryView.vue'),
      beforeEnter: authGuard,
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: () => import('../views/ConfirmedView.vue'),
    },
    {
      path: '/submit-error',
      name: 'submit-error',
      component: () => import('../views/SubmitErrorView.vue'),
    },
  ],
})

export default router
