import { readonly, ref } from 'vue'
import { authStore } from './store'

export const createAuthHolder = ({ apiBaseUrl, router, redirectTo }) => {
  const isAuthenticated = ref(false)
  const isLoading = ref(false)

  const callLogin = async ({ identifier, passcode }) => {
    const response = await fetch(`${apiBaseUrl}/login`, {
      method: 'POST',
      body: JSON.stringify({
        identifier,
        passcode,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (response.ok) {
      const responseBody = await response.json()
      return responseBody.token
    } else {
      throw Error(
        `Authentication failed. Login returned error status ${response.status} `,
      )
    }
  }

  return {
    async authenticate({ credentials }) {
      isLoading.value = true
      try {
        const token = await callLogin(credentials)
        await authStore.saveToken(token)
        isAuthenticated.value = true
        router.replace(redirectTo)
      } finally {
        isLoading.value = false
      }
    },
    async tryRestoringAuth() {
      try {
        const token = await authStore.getToken()
        if (token) {
          isAuthenticated.value = true
          router.replace(redirectTo)
        }
      } catch (e) {
        console.error(e)
        isAuthenticated.value = false
      }
    },
    async getToken() {
      try {
        return await authStore.getToken()
      } catch (e) {
        console.error(e)
        isAuthenticated.value = false
      }
    },
    isAuthenticated: readonly(isAuthenticated),
    isLoading: readonly(isLoading),
  }
}
